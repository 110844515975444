@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    scrollbar-width: thin;
    scrollbar-color: #8f54a0;
}

*::-webkit-scrollbar {
    width: 7px;
}

*::-webkit-scrollbar-track {
    background: #132025;
}

*::-webkit-scrollbar-thumb {
    background-color: #8f54a0;
    border-radius: 10px;
    border: 0px inset #000000;
}